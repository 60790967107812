import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import { HttpClient, provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, isDevMode, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { OktaAuthModule, OKTA_CONFIG } from "@okta/okta-angular";
import { BadgeModule } from "primeng/badge";
import { CarouselModule } from "primeng/carousel";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { ToastModule } from "primeng/toast";
import { AuthInterceptor } from "./app-core/interceptors/auth.interceptor";
import { ConfirmDeleteInterceptor } from "./app-core/interceptors/confirm-delete.interceptor";
import { ErrorCatchingInterceptor } from "./app-core/interceptors/error-catching.interceptor";
import { LoadingInterceptor } from "./app-core/interceptors/loading.interceptor";
import { ErrorService } from "./app-core/services/error.service";
import { AssecoService } from "./services/asseco.service";
import { AttachmentService } from "./services/attachment.service";
import { NominationService } from "./services/nomination.service";
import { environment } from "src/environments/environment";
import OktaAuth from "@okta/okta-auth-js";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import '@angular/common/locales/global/pl';
import { provideRouter, withComponentInputBinding, withViewTransitions } from "@angular/router";
import { routes } from "./app-routes";

const DEFAULT_LOCALE = 'pl-PL';
const oktaAuth = new OktaAuth(environment.okta);
export const appConfig: ApplicationConfig = {
    providers: [
        AssecoService,
        AttachmentService,
        NominationService,
        provideRouter(routes, withViewTransitions(), withComponentInputBinding()),
        importProvidersFrom(
            OktaAuthModule,
            MatSnackBarModule,
            MatDialogModule,
            MatProgressBarModule,
            ToastModule,
            BadgeModule,
            BrowserModule,
            CarouselModule,
            SidebarModule,
            MatButtonModule,
            MatIconModule,
            SkeletonModule,
            MatMenuModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (http: HttpClient) => new TranslateHttpLoader(http, `/assets/i18n/`),
                    deps: [HttpClient]
                }
            }), ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !isDevMode(),
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            })),

        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'PLN'
        },
        {
            provide: ErrorHandler,
            useClass: ErrorService,
        },
        {
            provide: OKTA_CONFIG,
            useValue: { oktaAuth }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorCatchingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ConfirmDeleteInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: localStorage.getItem('language') || DEFAULT_LOCALE
        },
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: 'yyyy-MM-dd' }
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        },
        provideAnimationsAsync(),
    ]
};