import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../app-core/services/notification.service';

@Injectable({
    providedIn: 'root'
})
export class ActiveAccountGuard {
    private readonly notificationService = inject(NotificationService);
    private readonly translate = inject(TranslateService);
    private readonly authService = inject(AuthService);


    canActivate(): boolean {
        if (!this.authService.accountActive()) {
            this.notificationService.showMsg(this.translate.instant('route.accountDisabled'), 'error');
        }
        return this.authService.accountActive();
    }
}
